import './cconsent';
import { start_medias, start_socials } from './thirdparties';
import $ from 'jquery';
import Modalbox from "./modal";

$(function() {
	const name = app_config.name;
	$.cc.run({
		target: '#cc-box',
		types: {
			'essential': {
				title: 'Cookies de ' + name,
				desc: 'Ces cookies sont nécessaires au fonctionnement de '+name+', il n\'est pas possible de les désactiver.',
				base: true
			},
			'social': {
				title: 'Réseaux sociaux',
				desc: 'Ces cookies permettent aux réseaux sociaux de proposer des services (j\'aime, partager)',
				base: false
			},
			'media': {
				title: 'Média',
				desc: 'Ces cookies permettent de voir les contenu hébergés en dehors de notre site (vidéo youtube)',
				base: false
			}
		},
		settings: {
			ignoreDoNotTrack: false,
			version: 2,
			txtInfo: '<strong><em>Ce site utilise des cookies provenant de sites tiers</em><br></strong>',
			txtPolicy: '<a href="/cookies-policy" id="cookie-policy" data-size="xl" data-title="Politique du site vis à vis des cookies">En savoir plus</a>',
			txtApprove: "J'accepte",
			txtDecline: 'Je refuse',
			txtShow: 'Voir le détails',
			txtTypes: 'Les types de cookies ci dessous sont utilisés par ce site. En cliquant sur le bouton "J\'accepte" seuls les types de cookies cochés seront activés. En cliquant sur "Je refuse", ils seront tous désactivés.',
			consent: function(types) {
				console.log('cookie types', types);
				const active = {};
				console.log(types);
				types.forEach(type => {
					active[type] = true;
				});
				console.log(active);
				if(active.media) {
					start_medias();
				}
				if(active.social) {
					start_socials();
				}
			}
		}
	});
	Modalbox.register($('#cookie-policy'));
});