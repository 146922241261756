
import * as bootstrap from 'bootstrap';

const Modalbox = {
    init: false,
    build: function(prefix) {
        const $m = $(`<div class="modal-content">`);
        const $h = $(`<div class="modal-header"/>`);
        $h.append(`<h4 id="${prefix}-title" class="modal-title"/>`);
        $h.append(`<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">`);
        $m.append($h);
        $m.append(`<div class="modal-body" id="${prefix}-body"/>`);
        $m.append(`<div class="modal-footer" id="${prefix}-footer"/>`);
        const $c = $(`<div class="modal-dialog" role="document" id="${prefix}-modal-dialog"/>`);
        $c.append($m);
        const $d= $(`<div id="${prefix}-box" class="modal fade" tabindex="-1" role="dialog" style="z-index:10000;"/>`);
        $d.append($c);
        $d.hide();
        $('body').append($d);
        
        this.bsModal = new bootstrap.Modal($d);
    },
    show: function(o) {
       if(!Modalbox.init) {
            Modalbox.build('dialog');
            Modalbox.init = true;
       }
       o = o || {};
       const $dialog = $('#dialog-box');
       const $dialog_body = $('#dialog-body');
       const $md = $('#dialog-modal-dialog');
       $dialog_body.empty();
       const $dialog_content = $(`<div id="dialog-content"/>`);
       $dialog_body.append($dialog_content);
       if(o.width) {
           let w = parseFloat(o.width);
           if(w < 1) {
               w =  ~~( $(window).width() * w );
           }
           o.width = ~~w;
       }

       if(o.fullheight) {
           o.height = false;
       } else {
           if(!o.height) {
               o.height = ~~($(window).width * .8);
           }
       }

       if( o.height ) {
           let h = parseFloat(o.height);
           if(h < 1) {
               h =  ~~( $(window).height() * h );
           }
           o.height = ~~h;
           $dialog_body.css({ 'max-height': o.height, 'height': o.height, 'overflow-y':'auto'});
       } else {
           //$dialog_body.css('max-height', o.maxheight);
           //$dialog_body.css('height', 'auto');
       }

       let size = o.size || 'lg';

       $md.attr('class', 'modal-dialog modal-'+size); // Init base classe

       if(o.centered) {
           $md.addClass('modal-dialog-centered');
       }

       if(o.title) {
            $('#dialog-title').html(o.title).show();
       } else {
    	   $('#dialog-title').empty();
       }
       let load = true;
       if(o.html) {
           $dialog_content.html(o.html);
       }
       if(o.footer) {
           $('#dialog-footer').html(o.footer).show();
       } else {
    	   $('#dialog-footer').hide();
       }
       console.log(o);

       if(o.img) {
          const $img = $('<img src="'+ o.img+'" class="img-responsive center-block"/>');
          $dialog_content.empty();
          $dialog_content.append($img);
          $img.error(function() {
            $dialog_content.hmtl(_T('img_load_error'));
          });
       }

       if(o.url) {
           load = false;
           $.ajax({
            url: o.url,
            dataType: 'html',
            success: (data) => {
                $dialog_content.html(data);
                console.log('ui');
                this.bsModal.show();
                if($.isFunction(o.success)) {
                    o.success(data);
                }
            }
        });
       }
       if(load) {
            this.bsModal.show();
       }
    },
    hide: function(prefix) {
       if(!prefix) {
           prefix = 'dialog';
       }
    this.bsModal.hide()
    },
    handler: function(ev) {
        const $e = $(this);
        const o = $e.data() || {};
        if(!o.img && $e.attr('href')) {
            o.url = $e.attr('href');
        }
        if($e.attr('title')) {
            o.title = $e.attr('title');
        }
        Modalbox.show(o);
        ev.preventDefault();
        return false;
    },
    register: function($e) {
        $e.on('click', Modalbox.handler);
    }
};


export default Modalbox;
