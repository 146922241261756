import 'vite/modulepreload-polyfill';

console.log('main.js django-vite is loaded');

import './main/jsssr_style.css';

// $ has been declared through plugin-inject vite module 
window.$ = $;
window.jQuery = $;
window.jquery = $;

// This line is enough to make carrousel and menu working 
import * as bootstrap from 'bootstrap';
// window.bootstrap = bootstrap;


import './main/config.js'; 
import './main/base.js'; 
import './main/cconsent.sw.js'; 
import './main/navbar.js';

import Modalbox from "./main/modal.js"; // another modal windows utility

import { reset_cookie_consent, start_piwik } from './main/thirdparties.js';
window.reset_cookie_consent = reset_cookie_consent;
window.start_piwik = start_piwik;
start_piwik();

// use class "modalbox-link" to open a page in a modal box instead of loading the entire page.
Modalbox.register($('.modalbox-link'));
