
if(!window.console) {
	window.console = {
        log: function(e) { }
    };
}

$.isOldIE = function() {
	return $('#has-ie').length > 0;
};

function msieversion() {
	var ua = window.navigator.userAgent;

    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    var trident = ua.indexOf('Trident/');
    if (trident > 0) {
        // IE 11 => return version number
        var rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }
	return false
}

/**
 * Grippenet ns
 */
window.gn = {
	ready: function() {
		$('html').removeClass('no-js');
		// if($.browser.msie) {
		if(msieversion()) {
			var v = Math.ceil(msieversion());
			v = 'ie'+ v;
			$('body').addClass('ie ' + v);
		}
		
		var from = encodeURI(document.location.pathname);
		$('#feedback-link').attr('href', '/feedback?from='+from);

		if( $.isOldIE() ) {
			$("body").prepend(`
				<div class="buorg" id="buorg" style="cursor: pointer;">
					<div class="buorg-pad" role="status" aria-live="polite">
						<span class="buorg-icon"> </span><b class="buorg-mainmsg">
							Votre navigateur Web n'est pas à jour.</b> <span class="buorg-moremsg">Mettez à jour votre navigateur pour plus de sécurité et de rapidité et une meilleure expérience sur ce site.</span> 
						<span class="buorg-buttons">
						<!--a id="buorgul" href="https://browser-update.org/update-browser.html?force_outdated=true#3.3.49:browser-update.org" target="_blank" rel="noopener">Mettre à jour le navigateur</a--> 
						<a id="buorgig" role="button" tabindex="0" onclick="$('div#buorg').hide();">Ignorer</a></span>
					</div>
					<style>
						.buorg-icon {width: 22px; height: 16px; vertical-align: middle; position: relative; top: -0.05em; display: inline-block; background: no-repeat 0px center url(https://browser-update.org/static/img/small/f.png);}
						.buorg {
							position:absolute;position:fixed;z-index:111111; width:100%; top:60px; left:0px; border-bottom:1px solid #A29330; text-align:center;  color:#000; background-color: #fff8ea; font: 18px Calibri,Helvetica,sans-serif; box-shadow: 0 0 5px rgba(0,0,0,0.2);animation: buorgfly 1s ease-out 0s;}
						.buorg-pad { padding: 9px;  line-height: 1.7em; }
						.buorg-buttons { display: block; text-align: center; }
						#buorgig,#buorgul,#buorgpermanent { 
							color: #fff; text-decoration: none; cursor:pointer; box-shadow: 0 0 2px rgba(0,0,0,0.4); padding: 1px 10px; border-radius: 4px; font-weight: normal; background: #5ab400;    white-space: nowrap;    margin: 0 2px; display: inline-block;
						}
						#buorgig { background-color: #edbc68;}
						@media only screen and (max-width: 700px){
							.buorg div { padding:5px 12px 5px 9px; line-height: 1.3em;}
						}
						@keyframes buorgfly {from {opacity:0;transform:translateY(-50px)} to {opacity:1;transform:translateY(0px)}}
						.buorg-fadeout {transition: visibility 0s 8.5s, opacity 8s ease-out .5s;}
					</style>
				</div>`)
		}

	}
};

// Ready loader
$(gn.ready);
