
let prevScrollpos = window.scrollY;

const originalTop = document.getElementById("main-navbar").style.top;
const burgerCheckbox = document.getElementById("iplesp-hamburger-cbx");
window.onscroll = function() {
  var currentScrollPos = window.scrollY;
  if (prevScrollpos > currentScrollPos || burgerCheckbox.checked) {
    document.getElementById("main-navbar").style.top = originalTop;
  } else {
    document.getElementById("main-navbar").style.top = `-${prevScrollpos}px`;
  }
  prevScrollpos = currentScrollPos;
} 

