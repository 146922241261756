
import './cookie_consent.css';
import $ from 'jquery';

const cc = {
	target: null,
	settings: {
		ignoreDoNotTrack: false,
		version: 1,
		expires: 365,
		txtInfo: 'Cookies are used',
		txtApprove: 'Approve',
		txtDecline: 'Decline',
		txtShow: 'Show',
		txtTypes: 'Types',
		txtPolicy: 'policy',
		needRestart: false,
		consent: null, // callback call to apply consent
		decline: null // callback called to apply decline
	},
	types: {
		"essential": {
			title: 'Essential cookies',
			desc: '',
			base: true
		}
	},
		get_cookie: function (c_name) {
		let i, x, y, ARRcookies = document.cookie.split(";");
		for (i = 0; i < ARRcookies.length; i++) {
			x = ARRcookies[i].substring(0, ARRcookies[i].indexOf("="));
			y = ARRcookies[i].substring(ARRcookies[i].indexOf("=") + 1);
			x = x.replace(/^\s+|\s+$/g, "");
			if (x == c_name) {
				return decodeURI(y);
			}
		}
		return false;
	},
	set_cookie: function (name, value, expirydays) {
		const exdate = new Date();
		exdate.setDate(exdate.getDate() + expirydays);
		console.log('COOKIE : ' + name )
		document.cookie = name + '=' + value + '; expires=' + exdate.toUTCString() + ';SameSite=Lax ' +'; path=/'
	},
	delete_cookie: function (key) {
		const date = new Date();
		date.setDate(date.getDate() - 1);
		document.cookie = encodeURI("cc_" + key) + '=; path=/; expires=' + date.toUTCString();
	},
	check_donottrack: function(){
		cc.checkeddonottrack = true;
		if (!cc.settings.ignoreDoNotTrack) {
			if (navigator.doNotTrack == "yes" || navigator.doNotTrack == "1" || navigator.msDoNotTrack == "yes" || navigator.msDoNotTrack == "1") {
				cc.settings.dontnottrack  = true;
			} else {
				cc.settings.dontnottrack  = false;
			}
		}
	},		
	check_approval: function() {
		const response = cc.get_cookie('cc_response');
		if(response !== false) {
			cc.hide_banner();
			// now run response
			if(response == 'consent') {
				if(cc.settings.consent) {
					let types = cc.get_cookie('cc_types');
					types = types.split(',');
					cc.settings.consent(types);
				}
			} else {
				if(cc.settings.decline) {
					cc.settings.decline.call();
				}
			}
			return;
		}
		cc.show_banner();
	},
	run: function(conf) {
		cc.target = $(conf.target),
		cc.settings = $.extend(cc.settings, conf.settings);
		cc.types = $.extend(cc.types, conf.types);
		cc.check_donottrack();
		cc.check_approval();			
	},
	onReset: function() {
		cc.delete_cookie('response');
		cc.delete_cookie('types');
		if (cc.settings.needRestart) {
			document.location.reload();
		}
		else {
			cc.check_approval();
		}
	},
	onConsent: function() {
		cc.set_cookie('cc_response', 'consent', cc.settings.expires);
		const conf = cc.settings, tt = [];
		for(let name in cc.types) {
			const type = cc.types[name];
			if( type.base) {
				continue;
			}
			if( $('#cc-'+name).is(':checked') ) {
				tt.push(name);
			}
		}
		cc.set_cookie('cc_types', tt.join(','), cc.settings.expires);
		cc.hide_banner();
		if(conf.consent) {
			conf.consent(tt);
		}
		if(conf.needRestart) {
			document.location.reload();
		}
	},
	onDecline: function() {
		const conf = cc.settings;
		cc.set_cookie('cc_response', 'decline', cc.settings.expires);
		cc.delete_cookie('types');
		cc.hide_banner();
		if(conf.decline) {
			conf.decline();
		}
		if(conf.needRestart) {
			document.location.reload();
		}
	},
	show_banner: function() {
		const conf = cc.settings
		let b = '<dl>', checked, old;
		for(let name in cc.types) {
			const type_cookie = cc.types[name];
			old = cc.get_cookie()
			b += '<dt>';
			if(type_cookie.base) {
				b += "&nbsp;&nbsp;&nbsp;"
			} else {
				checked = true;
				if(conf.dontnottrack) {
					checked = false;
				}
				if(old !== false) {
					checked = old_state;
				}
				checked = checked ? 'checked="checked"' : '';
				b += '<input type="checkbox" id="cc-'+name+'" '+checked+'/>';
			}
			b += '<label for="cc-'+name+'">'+type_cookie.title+'</label></dt><dd>'+type_cookie.desc+'</dd>';
		}
		b += '</dl>';
		const html = `
			<div id="cc-banner">
				<span class="cc-info">${conf.txtInfo}</span>
				<div id="cc-actions">
					<button id="cc-show">${conf.txtShow}</button> 
					<button id="cc-consent">${conf.txtApprove}</button> 
					<button id="cc-decline">${conf.txtDecline}</button>
				</div>
				<div id="cc-policy">${conf.txtPolicy}</div>
				<div id="cc-types" style="display:none"><hr><p>${conf.txtTypes}</p>${b}</div>
			</div>`;
		cc.target.html(html);
		$('body').addClass('cc-showed');
		$('#cc-show').click(function(){ 
			const $e = $('#cc-types');
			if( $e.is(':visible') ) {
				$e.slideUp();
			} else {
				$e.slideDown();	
			}
		});			
		$('#cc-consent').click(cc.onConsent);	
		$('#cc-decline').click(cc.onDecline);			
	},
	hide_banner: function() {
		cc.target.hide();
		$('body').removeClass('cc-showed');	
	}
};
$.cc = cc;
