
/**
 * Social third parties
 */
export function start_socials() {
 let html = '';
 html += '<iframe src="//platform.twitter.com/widgets/follow_button.html?screen_name=grippenet&lang=fr&show_count=false" allowtransparency="true" frameborder="0" scrolling="no" style="width:169px; height:21px;"></iframe>';
//  html += '<iframe src="//www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.facebook.com%2Fpages%2FGrippeNet%2F307427382625293&amp;send=false&amp;layout=button_count&amp;width=169&amp;show_faces=true&amp;action=like&amp;colorscheme=light&amp;font=verdana&amp;height=21" scrolling="no" frameborder="0" style="border:none; overflow:hidden; width:169px; height:21px;" allowTransparency="true"></iframe>';
 html += '<div class="addthis_toolbox addthis_default_style "><a class="addthis_button_preferred_1"></a><a class="addthis_button_preferred_2"></a><a class="addthis_button_preferred_3"></a>';
 html += '<a class="addthis_button_preferred_4"></a><a class="addthis_button_compact"></a><a class="addthis_counter addthis_bubble_style"></a></div>';
 html += '<script type="text/javascript" src="//s7.addthis.com/js/300/addthis_widget.js#pubid=xa-4f1e7fb169da798d" async="async"></script>';
 html += '</div>';
 $('#social-widgets').html(html);
}

export function start_medias() {
	$('.video-youtube').each(function() {
		const width = $(this).data('width') || 700;
		const $e = $(this);
		$e.empty();
		const conf = $e.data();
		const $video_container = $(`<div class="ratio ratio-16x9 mx-auto" style="width:${width}px;max-width: 100% !important;">`);
		const $i = $(`<iframe allow="" frameborder="0" allowfullscreen="" style="max-width: 100%;height: 100%;" >`);
		
		
		$video_container.append($i);
		// $i.attr('height', conf.height);
		// $i.attr('width', conf.width);
		$i.attr('src', conf.src);
		$i.attr('data-cookieblock-src', conf.src)
		$i.attr('data-cookieconsent', 'marketing')
		if(conf.title) {
			$i.attr('title', conf.title);
		}
		$e.append($video_container);
	});
};

export function reset_cookie_consent() {
	$.cc.onReset();
	document.location.reload();
};

/**
 * Piwik/Matomo
 */
export function start_piwik() {
	const _paq = window._paq = [];

	let u = 'piwik.iplesp.upmc.fr'; // pourquoi ne pas mettre a.grippenet.fr ? 
	if(document.location.hostname.includes("covidnet.fr")) {
		u = 'a.grippenet.fr';
	}
	if(document.location.hostname.includes("grippenet.fr")) {
		u = 'a.grippenet.fr';
	}
	u = 'https://' + u + '/';

	_paq.push(['trackPageView']);
	_paq.push(['enableLinkTracking']);
	_paq.push(['setTrackerUrl', u+'piwik.php']);
	_paq.push(['setSiteId', 1]);
	
	var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
	g.type='text/javascript'; g.async=true; g.defer=true; g.src=u+'piwik.js'; s.parentNode.insertBefore(g,s);
}
